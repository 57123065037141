import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { keccak256, isAddress } from 'ethers/lib/utils';
import contractabi from './contracts/artifacts/contractabi.json';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from "styled-components";
import { initWeb3Onboard } from './services'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Countdown from 'react-countdown';
import {
  useAccountCenter,
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets,
  useSetLocale
} from '@web3-onboard/react'
import './App.css'
import { borderLeftColor } from '@mui/system';



let provider

const NFTcontractAddress = "0xFa0F5110e3Acb2529b6B8E7AB6E646FA2a1C388c";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{ height: '15px', borderRadius: "30px", background: "#0073ff3b" }} variant="determinate" {...props} />
      </Box>

    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};



function MintPage() {
  const [{ wallet }, connect, disconnect, updateBalances, setWalletModules] =
    useConnectWallet();
  const [notifications, customNotification, updateNotify] = useNotifications();
  const connectedWallets = useWallets();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [web3Onboard, setWeb3Onboard] = useState(null);
  const [{ chains, connectedChain, settingChain }, setChain] = useSetChain()
  const [error, setError] = useState('');
  const [errCode, setErrCode] = useState(0);
  const [data, setData] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [accountCenterPosition, setAccountCenterPosition] = useState('topRight')
  const [notifyPosition, setNotifyPosition] = useState('bottomRight')
  const [locale, setLocale] = useState('en')
  const [accountCenterSize, setAccountCenterSize] = useState('normal')
  const [accountCenterExpanded, setAccountCenterExpanded] = useState(false)

  const [progress, setProgress] = React.useState(10);


  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, [])

  useEffect(() => {
    console.log(notifications)
  }, [notifications])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )


  }, [connectedWallets, wallet])

  useEffect(() => {
    if (connectedWallets.length != 0) {
      fetchData();
    }

  }, [connectedWallets])

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        await connect({ autoSelect: previouslyConnectedWallets[0], disableModals: true })
      }
      setWalletFromLocalStorage()
    }
  }, [web3Onboard, connect])

  async function fetchData() {
    console.log(connectedChain.id)
    const provider = new ethers.providers.Web3Provider(connectedWallets[0].provider, 'any');
    const contract = new ethers.Contract(NFTcontractAddress, contractabi.abi, provider);

    try {
      let cost;

      const totalSupply = await contract.totalSupply();
      const step = await contract.sellingStep();
      if (step == 1) {
        cost = await contract.publicSalePrice();

        document.getElementById("public").classList.add("active");
      }
      const object = { "cost": String(cost), "totalSupply": String(totalSupply), "step": String(step) }
      console.log(object);
      setData(object);
      setLoading(false);

    }
    catch (err) {
      setError(err.message);
    }

  }


  const handleConnect = async () => {
    connect()
      .then(console.log(connectedWallets))
      .catch(e => console.Console.log(e));
  }
  async function mint() {
    const provider = new ethers.providers.Web3Provider(connectedWallets[0].provider, 'any');
    const signer = provider.getSigner();
    const contract = new ethers.Contract(NFTcontractAddress, contractabi.abi, signer);
    try {
      let overrides = {
        from: connectedWallets[0]["accounts"][0]["address"],
        value: String(data.cost * quantity),
      }
      const transaction = await contract.publicSaleMint(connectedWallets[0]["accounts"][0]["address"], quantity, overrides);
      await transaction.wait();
      fetchData();
    }
    catch (err) {
      setError(err.message);
      handleError(err.message);
    }
  }

  async function handleError(err) {
    if (err.includes("Max per wallet")) {
      console.log('You are trying to mint more than the allowed amount.');
      setErrCode(2);
      handleShow();
    }
    if (err.includes("user rejected transaction")) {
      console.log('User denied the transaction signature.');
      setErrCode(1);
      handleShow();
    }
    if (err.includes("insufficient funds")) {
      console.log('Insufficient funds');
      setErrCode(3);
      handleShow();
    }

  }


  function valueUp() {
    if (quantity < 10) {
      setQuantity(quantity + 1);
    }
  }
  function valueDown() {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }
  const switchNetworkBSC = async () => {
    await setChain({ chainId: '0x38' })
  }

  return (
    <main>


      <section className="main">

        <div className="logo"><img src="logo.png" alt="" /></div>
        <div className='main-content'>


          <div className="containerr onboard">
            <div className="phases">


              <div className="phase" id="public">
                Public
              </div>
            </div>
            <h2>Nibirium</h2>

            {<>
              <div>
                {!wallet && (
                  <button
                    className="mintbutton"
                    onClick={
                      handleConnect
                    }
                  >
                    CONNECT
                  </button>
                )}

                {wallet && connectedChain.id == "0x38" && loading && <h2>Loading...</h2>}
                {wallet && connectedChain.id !== "0x38" && <div className="buttonswitch" onClick={switchNetworkBSC}><h2>Switch to BSC Network</h2><img src="/assets/bsc.svg" className='bsclogo' alt="" /></div>}

                {!loading && wallet && connectedChain.id == "0x38" && (
                  <div className="minting">
                    {data.step != 0 && data.step != null ? (<>
                      <div className="cost">
                        <h2>Price</h2>
                        <h3>{data.cost / 10 ** 18} <span>BNB</span></h3>
                      </div>

                      <div className='progress'>
                        <h3 className="minted">Total minted &nbsp;({data.totalSupply} / 2222) - {Math.round((data.totalSupply * 100 / 2222)) + '%'}</h3>
                        <Box sx={{ width: '100%', height: '60px' }}>
                          <LinearProgressWithLabel value={(data.totalSupply * 100 / 2222)} />
                        </Box>
                      </div>
                    </>
                    ) : (
                      <div>
                        <h3>Sale has not started yet.</h3>
                      </div>
                    )
                    }


                    <br></br>
                    <br></br>

                    {data.step == 1 && <div>
                      <div className='quantitymint'>
                        <h2>Quantity</h2>
                        <input
                          type="number"
                          id="quantity"
                          min="1"
                          max="10"
                          step="1"
                          value={quantity}
                        />
                        <div className="quantitybuttons">
                          <div className="arrowup" onClick={valueUp}></div>
                          <div className="arrowdown" onClick={valueDown}></div>
                        </div>
                      </div>
                      <button className="mintbutton" onClick={mint}>MINT</button>
                    </div>}



                  </div>
                )}
              </div>
            </>}



            {errCode == 1 &&

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  You rejected the transaction. Try minting again.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>

                </Modal.Footer>
              </Modal>

            }
            {errCode == 2 &&

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  You are trying to mint more than the allocated amount for your wallet during this sale.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>

                </Modal.Footer>
              </Modal>

            }
            {errCode == 3 &&

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Not enough funds.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>

                </Modal.Footer>
              </Modal>

            }

          </div>




        </div>
      </section>
    </main>
  )
}

export default MintPage
